module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Olly Nevard","short_name":"Olly Nevard","start_url":"/","background_color":"#2C292D","theme_color":"#FF6188","display":"standalone","icon":"static/images/logo_light_hex_maskable.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icons/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"ollynevard.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"DobtyiFpve4-wXr8MqqllJi2pJoD_Rj1E9CqTbcSJxk","initOptions":{"persistence":"memory"}},
    }]
